import moment from 'moment';
// 已绑定银行卡
import trains_common_queryBillStatus from "@/lib/data-service/haolv-default/consumer_trains_common_queryBillStatus.js";
import credit_billList from "@/lib/data-service/haolv-default/consumer_credit_billList.js";

export default {
    name: "HaveBill",
    data () {
        return {
            loading: false,
            form: {
                billStartDate: '',   //交易开始日期
                billEndDate: '',    //交易结束日期
                billStatus: 0,   //账单状态
                currentPage: 1,   //查询页码
                pageSize: 10   //查询条数
            },
            transactionDate: [moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')],
            pickerOptions: {
                disabledDate (time) {
                    const now = Date.now()
                    const threeYearsAgo = moment(now).add(-3, 'years')._d;
                    return time.getTime() > now || time.getTime() < threeYearsAgo;
                },
            },
            billStatusList: [{
                ban: "全部",
                type: 0
            }],
            billList: [{
                settleCycle: "",   //结算周期
                billDate: "",   //账单日
                billRefundDate: "",   //最后结算日
                billAmount: 0,   //账单金额
                overdueDays: 0,   //已逾期天数
                billStatusText: ""   //还款状态
            }],
            tableList: [],
            pager: {
                total: 0,
                pageSizes: [10, 15, 20, 25, 30],
            }
        };
    },
    computed: {},
    methods: {
        //初始化
        init () {
            this.tableList = [
                {
                    label: "结算周期",
                    prop: "settleCycle"
                },
                {
                    label: "账单日",
                    prop: "billDate"
                },
                {
                    label: "最后结算日",
                    prop: "billRefundDate"
                },
                {
                    label: "订单总额",
                    prop: "orderAmount"
                },
                {
                    label: "服务费",
                    prop: "serviceFeeAmount"
                },
                {
                    label: "账单金额",
                    prop: "billAmount"
                },
                {
                    label: "已逾期天数",
                    prop: "overdueDays"
                },
                {
                    label: "还款状态",
                    prop: "billStatusText"
                }
            ]
        },
        async getBillStatusList () {
            try {
                if (this.loading) return
                this.loading = true
                const res = await trains_common_queryBillStatus()
                this.billStatusList = res.datas;
            } catch (error) {
                this.$message({
                    type: "error",
                    message: '获取账单状态失败'
                })
            } finally {
                this.loading = false
            }
        },
        async getBillList () {
            try {
                if (this.loading) return
                this.loading = true
                const res = await credit_billList(this.form)
                this.billList = res.datas.list;
            } catch (error) {
                this.billList = []
                this.$message({
                    type: "error",
                    message: '获取账单列表失败'
                })
            } finally {
                this.loading = false
            }
        },
        searchClick () {
            this.form.currentPage = 1
            this.getBillList()
        },
        billDetailClick (billId) {
            this.$router.push({
                name: 'admin-finance-credit-have-bill-detail',
                query: {
                    billId
                }
            });
        },
        toRepayClick (billId) {
            this.$router.push({
                name: 'admin-finance-credit-repayments',
                query: {
                    billId
                }
            });
        },
        currentPageChange (currentPage) {
            this.form.currentPage = currentPage
            this.getBillList()
        },
        pageSizeChange (pageSize) {
            this.form.pageSize = pageSize
            this.currentPageChange(1)
        }
    },
    created (){
        this.init()
    },
    async activated () {
        await this.getBillStatusList()
        await this.getBillList()
    },
    watch: {
        transactionDate: {
            immediate: true,
            handler (val) {
                if (val) {
                    this.form.billStartDate = val[0]
                    this.form.billEndDate = val[1]
                } else {
                    this.form.billStartDate = ''
                    this.form.billEndDate = ''
                }
            }
        }
    }
};